export type NullToUndefinedProps<Base> = NonNullable<{
  [Key in keyof Base]: null extends Base[Key]
    ? Exclude<Base[Key], null> | undefined
    : Base[Key]
}>

export type NoNullOrUndefinedProps<Base> = NonNullable<{
  [Key in keyof Base]-?: NonNullable<Base[Key]>
}>

export type UndefinedToNullProps<Base> = NonNullable<{
  [Key in keyof Base]: undefined extends Base[Key]
    ? Exclude<Base[Key], undefined> | null
    : Base[Key]
}>

export function convertNullToUndefinedProps<T>(
  obj: T,
): NullToUndefinedProps<T> {
  const res: any = {}

  for (const key of Object.keys(obj) as (keyof T)[]) {
    if (obj[key] === null) {
      res[key] = undefined
    } else {
      res[key] = obj[key]
    }
  }

  return res as NullToUndefinedProps<T>
}

export function removeNullOrUndefinedProps<T>(
  obj: T,
): NoNullOrUndefinedProps<T> {
  const res: any = {}

  for (const key of Object.keys(obj) as (keyof T)[]) {
    if (obj[key] !== null && obj[key] !== undefined) {
      res[key] = obj[key]
    }
  }

  return res as NoNullOrUndefinedProps<T>
}

export function convertUndefinedToNullProps<T>(
  obj: T,
): UndefinedToNullProps<T> {
  const res: any = {}

  for (const key of Object.keys(obj) as (keyof T)[]) {
    if (obj[key] === undefined) {
      res[key] = null
    } else {
      res[key] = obj[key]
    }
  }

  return res as UndefinedToNullProps<T>
}

export function isDefined<T>(obj: T | null | undefined): obj is T {
  return obj !== null && obj !== undefined
}

export function maybeMap<I, O>(
  obj: I | null | undefined,
  mapFn: (o: I) => O,
): O | undefined {
  return isDefined(obj) ? mapFn(obj) : undefined
}

export async function maybeMapAsync<I, O>(
  obj: I | null | undefined,
  mapFn: (o: I) => Promise<O>,
): Promise<O | undefined> {
  return isDefined(obj) ? await mapFn(obj) : undefined
}

export function convertToNonNullArray<T>(arr: T[]): NonNullable<T>[] {
  return arr.filter(
    (val) => val !== null && val !== undefined,
  ) as NonNullable<T>[]
}

export function containsDuplicates<T>(
  items: T[],
  keyMapper: (item: T) => string,
): boolean {
  const map = new Map<string, T>()

  for (const item of items) {
    map.set(keyMapper(item), item)
  }

  return map.size !== items.length
}
