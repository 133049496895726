import { isUndefined } from "lodash"

export function getEnvString(key: string, defaultValue = ""): string {
  if (key.indexOf("NEXT_PUBLIC_") === 0) {
    throw new Error(
      "The '[]' syntax does not work for client-side environment " +
        `variables, please use 'process.env.${key} instead`,
    )
  }
  const value = process.env[key]
  if (value === undefined || value === "") {
    return defaultValue
  }
  return value
}

export function getEnvBool(key: string, defaultValue = false): boolean {
  if (key.indexOf("NEXT_PUBLIC_") === 0) {
    throw new Error(
      "The '[]' syntax does not work for client-side environment " +
        `variables, please use 'process.env.${key} instead`,
    )
  }

  let value = process.env[key]
  if (isUndefined(value)) return defaultValue

  value = value.trim().toLowerCase()
  return value === "1" || value === "true"
}
