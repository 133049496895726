import i18n from "i18next"
import { initReactI18next } from "react-i18next"

// Importing translation files
import translationEN from "./locales/en/common.json"
import translationES from "./locales/es/common.json"

// Resources object containing translations
const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
}

i18n
  // Pass the i18n instance to react-i18next.
  .use(initReactI18next)
  .init({
    resources,
    lng: "en", // default language
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  })

export default i18n
