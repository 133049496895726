import { initializeApp, getApps, getApp, FirebaseApp } from "firebase/app"
import { Auth, getAuth, connectAuthEmulator } from "firebase/auth"
import {
  Database,
  getDatabase,
  connectDatabaseEmulator,
} from "firebase/database"

import { isStringNotEmpty } from "utils/strings"

const config = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_WEB_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_WEB_AUTH_DOMAIN,
  databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
}

let connected = false

const initFirebase = (): {
  firebaseApp: FirebaseApp
  auth: Auth
  db: Database
} => {
  const emulatorAuthHost = process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST
  const emulatorDbHost = process.env.NEXT_PUBLIC_FIREBASE_DATABASE_EMULATOR_HOST

  if (!getApps().length) {
    initializeApp(config)
  }

  const firebaseApp = getApp()
  const auth = getAuth(firebaseApp)
  const db = getDatabase(firebaseApp)

  if (!connected) {
    if (isStringNotEmpty(emulatorAuthHost)) {
      connectAuthEmulator(auth, emulatorAuthHost)
    }

    if (isStringNotEmpty(emulatorDbHost)) {
      const [host, port] = emulatorDbHost.split(":")
      connectDatabaseEmulator(db, host, parseInt(port))
    }
    connected = true
  }

  return { firebaseApp, auth, db }
}

export { initFirebase }
