import { useEffect } from "react"
import { useUser } from "components/useUser"
import { Logger } from "Logger"
import { isStringEmpty } from "utils/strings"
const Log = Logger("components/useAnalytics")

const SEGMENT_WRITE_KEY: string =
  process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY ?? ""

declare global {
  interface Window {
    analytics: Analytics
  }
}

// Minimized Analytics interface
// Most methods ommited, please add more if needed
interface Analytics {
  identify: (
    id: string,
    traits?: Record<string, any>,
    options?: Record<string, any>,
    cb?: () => void,
  ) => void
  track: (
    event: string,
    props?: Record<string, any>,
    options?: Record<string, any>,
    cb?: () => void,
  ) => void
  page: (
    category?: string,
    name?: string,
    props?: Record<string, any>,
    options?: Record<string, any>,
  ) => void
}

const analyticsMock: Analytics = {
  identify: () => undefined,
  track: () => undefined,
  page: () => undefined,
}

export function useAnalytics(): Analytics {
  if (typeof window === "undefined") return analyticsMock

  // Create a queue, but don't obliterate an existing one!
  const analytics: any = (window.analytics = window.analytics || [])
  const { user, isLoading } = useUser({})

  useEffect(() => {
    if (isLoading) return
    analytics.identify(user?.id)
  }, [user?.id])

  // If the real analytics.js is already on the page return.
  if (analytics.initialize) return analytics as Analytics
  // If the snippet was invoked already show an error.
  if (analytics.invoked) {
    return analytics as Analytics
  }

  Log.debug("Initializing analytics")

  // Invoked flag, to make sure the snippet
  // is never invoked twice.
  analytics.invoked = true
  // A list of the methods in Analytics.js to stub.
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
    "addSourceMiddleware",
    "addIntegrationMiddleware",
    "setAnonymousId",
    "addDestinationMiddleware",
  ]
  // Define a factory to create stubs. These are placeholders
  // for methods in Analytics.js so that you never have to wait
  // for it to load to actually record data. The `method` is
  // stored as the first argument, so we can replay the data.
  analytics.factory = function (method: string) {
    return function (...args: any[]) {
      analytics.push([method, ...args])
      return analytics
    }
  }
  // For each of our methods, generate a queueing stub.
  for (let i = 0; i < analytics.methods.length; i++) {
    const key = analytics.methods[i]
    analytics[key] = analytics.factory(key)
  }
  // Define a method to load Analytics.js from our CDN,
  // and that will be sure to only ever load it once.
  analytics.load = function (key: string, options: Record<string, any>) {
    // Create an async script element based on your key.
    const script = document.createElement("script")
    script.type = "text/javascript"
    script.async = true
    script.src =
      "https://seg.coastlineacademy.com/analytics.js/v1/" +
      key +
      "/analytics.min.js"
    // Insert our script next to the first script element.
    const first = document.getElementsByTagName("script")[0]
    if (!first || !first.parentNode) {
      Log.error("No script tag in document")
      return
    }
    first.parentNode.insertBefore(script, first)
    analytics._loadOptions = options
  }
  analytics._writeKey = SEGMENT_WRITE_KEY
  // Add a version to keep track of what's in the wild.
  analytics.SNIPPET_VERSION = "4.13.2"

  // Load Analytics.js with your key, which will automatically
  // load the tools you've enabled for your account. Boosh!
  if (isStringEmpty(SEGMENT_WRITE_KEY)) {
    Log.warn("Segment API key is missing, analytics won't be loaded")
    return analytics
  }

  analytics.load(SEGMENT_WRITE_KEY)
  return analytics as Analytics
}
