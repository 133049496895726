import { isValid, parseISO } from "date-fns"

export function isStringEmpty(s: string | undefined | null): boolean {
  return (s ?? "").trim().length === 0
}

export function isStringNotEmpty(s: string | undefined | null): s is string {
  return !isStringEmpty(s)
}

export function emptyStringToUndefined(
  s: string | undefined | null,
): string | undefined {
  if (isStringEmpty(s)) {
    return undefined
  }

  return s ?? undefined
}

export function emptyStringToDefault(
  s: string | undefined | null,
  defaultVal: string,
): string {
  if (isStringNotEmpty(s)) return s
  return defaultVal
}

export function maybeParseFloat(s: string): number | undefined {
  const result = parseFloat(s)

  if (isNaN(result)) {
    return undefined
  }

  return result
}

export function maybeParseInt(s: string): number | undefined {
  const result = parseInt(s, 10)

  if (isNaN(result)) {
    return undefined
  }

  return result
}

export function maybeParseDate(s: string): Date | undefined {
  const d = parseISO(s)

  if (!isValid(d)) {
    return undefined
  }

  return d
}

export function parseDate(s: string, defaultDate: Date): Date {
  const d = parseISO(s)

  if (!isValid(d)) {
    return defaultDate
  }

  return d
}

export function prefix(prefix: string, s?: number | null): string | undefined {
  if ((s ?? undefined) === undefined) {
    return undefined
  }

  return `${prefix}${s}`
}

export function postfix(
  postfix: string,
  s?: number | null,
): string | undefined {
  if ((s ?? undefined) === undefined) {
    return undefined
  }

  return `${s}${postfix}`
}

export function formatMinutesToHours(minutes: number): string {
  const hours = Math.floor(minutes / 60)
  const minutesLeft = minutes % 60

  return `${hours} hour${
    hours === 0 || hours > 1 ? "s" : ""
  } ${minutesLeft} minute${minutesLeft === 0 || minutesLeft > 1 ? "s" : ""}`
}

export function makeSlug(str: string): string {
  return str.replace(/\s+/g, "-")
}

export function shortenId(str: string): string {
  if (str.length <= 8) return str
  return str.slice(0, 2) + "…" + str.slice(str.length - 4, str.length)
}

export function firstNotEmpty(
  arr: (string | null | undefined)[],
  fallback: string,
): string {
  for (const s of arr) {
    if (isStringNotEmpty(s)) return s
  }

  return fallback
}

export function camelCaseToTitleCase(text: string): string {
  const result = text.replace(/([A-Z])/g, " $1")
  return result.charAt(0).toUpperCase() + result.slice(1)
}
